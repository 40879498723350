import type { FunctionComponent } from "react"

import { clsx } from "clsx"

import { Link } from "@jobteaser/spark/components/Link"

import { Icon } from "@/modules/headerFooterFO/Header/components/Icon/Icon"
import { useLocale } from "@/modules/locales/useLocale"

import styles from "./LoginLink.module.css"
import navigationStyles from "./Navigation.module.css"

const monolithBaseUrl = process.env.NEXT_PUBLIC_MONOLITH_API_BASE_URL

type LoginLinkProps = {
  className?: string
  label: string
}

export const LoginLink: FunctionComponent<LoginLinkProps> = ({ className, label }) => {
  const currentLocale = useLocale()

  const urlToSignIn = `${monolithBaseUrl}/users/auth/connect?i18n_locale=${currentLocale}&ui_locales=${currentLocale}&organization_domain=public`

  return (
    <Link href={urlToSignIn} className={clsx(styles.main, navigationStyles.link, className)}>
      <span className={styles.icon} aria-hidden>
        <Icon name="Profile" />
      </span>
      {label}
    </Link>
  )
}
