import type { Page } from "@/modules/corporate/types/pages"
import type { FunctionComponent } from "react"

import { clsx } from "clsx"
import { usePathname } from "next/navigation"
import { useEffect, useRef, useState } from "react"

import IconArrowDown from "@jobteaser/ui-kit/assets/icons/jds_arrowDown.svg"
import IconArrowRight from "@jobteaser/ui-kit/assets/icons/jds_arrowRight.svg"
import { Button } from "@jobteaser/ui-kit/components/Button"
import { Icon, ICON_SIZES } from "@jobteaser/ui-kit/components/Icon/Icon"

import { InternalLink } from "@/modules/corporate/components/InternalLink"
import { URL_PATHNAME } from "@/modules/corporate/constants"
import { useCorporateTrackingClick } from "@/modules/corporate/tracking/useCorporateTrackingClick"
import { useLocale } from "@/modules/locales/useLocale"

import styles from "./Navigation.module.css"

type MainNavigationProps = {
  pageNavigation: Page[]
  onNavigationLinkClick: () => void
}

export const MainNavigation: FunctionComponent<MainNavigationProps> = ({ pageNavigation, onNavigationLinkClick }) => {
  const [openedDropdown, setOpenedDropdown] = useState("")
  const dropdownRef = useRef<HTMLUListElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)
  const pathname = usePathname()
  const locale = useLocale()
  const trackClickEvent = useCorporateTrackingClick()

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (
        dropdownRef.current &&
        buttonRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setOpenedDropdown("")
      }
    }
    const isMobileOrTablet = window.innerWidth <= 1024
    const isTouchDevice = "ontouchstart" in window || navigator.maxTouchPoints > 0

    if (!isMobileOrTablet && !isTouchDevice) {
      window.addEventListener("mouseup", handleClickOutside)
    }
    return () => {
      if (!isMobileOrTablet && !isTouchDevice) {
        window.removeEventListener("mouseup", handleClickOutside)
      }
    }
  }, [])

  return (
    <ul className={clsx(styles.list, styles["list--secondary"])} role="menubar">
      {pageNavigation.map(page => {
        const hasChildrenPageActive = [page, ...(page.childrenPages || [])].find(
          ({ slug }) => pathname === `/${locale}/${URL_PATHNAME}/${slug}`
        )

        return (
          <li
            key={page.menuTitle || page.title}
            className={clsx(styles.listItem, {
              [styles["list--hasChildrenActive"]]: !!hasChildrenPageActive,
              [styles["list--isDropdownOpened"]]: openedDropdown === page.slug,
            })}
            role="presentation"
          >
            {(page.childrenPages || []).length ? (
              <Button
                className={clsx(styles.link, styles["link--isButton"])}
                icon={IconArrowDown}
                iconOnRight
                aria-haspopup="true"
                aria-expanded={openedDropdown === page.slug ? "true" : "false"}
                role="menuitem"
                onClick={() => {
                  setOpenedDropdown(openedDropdown === page.slug ? "" : page.slug)
                  trackClickEvent("corporate_navigation", {
                    clicked_url: `/${locale}/${URL_PATHNAME}/${page.slug}`,
                    visual_context: `main_${openedDropdown === page.slug ? "close" : "open"}`,
                  })
                }}
                id={page.menuTitle || page.title}
                ref={buttonRef}
              >
                {page.menuTitle || page.title}
              </Button>
            ) : (
              <InternalLink
                page={page}
                className={styles.link}
                onClick={() => {
                  onNavigationLinkClick()
                  setOpenedDropdown("")
                  trackClickEvent("corporate_navigation", {
                    clicked_url: `/${locale}/${URL_PATHNAME}/${page.slug}`,
                    visual_context: "main_page",
                  })
                }}
              />
            )}
            {(page.childrenPages || []).length ? (
              <ul ref={dropdownRef} className={styles.dropdown} role="menu" aria-label={page.menuTitle || page.title}>
                {[page, ...(page.childrenPages || [])].map(childPage => (
                  <li className={styles.dropdownItem} key={childPage.title} role="presentation">
                    <InternalLink
                      page={{
                        slug: childPage.slug,
                        title: childPage.navigationTitle || childPage.title,
                      }}
                      className={styles.dropdownLink}
                      onClick={() => {
                        setOpenedDropdown("")
                        onNavigationLinkClick()
                        trackClickEvent("corporate_navigation", {
                          clicked_url: `/${locale}/${URL_PATHNAME}/${childPage.slug}`,
                          visual_context: "page",
                        })
                      }}
                    >
                      <div className={styles.dropdownLinkTitle}>{childPage.navigationTitle || childPage.title}</div>
                      <div className={styles.dropdownLinkSubtitle}>{childPage.subtitle}</div>
                      <Icon
                        className={styles.dropdownLinkIcon}
                        xlinkHref={`#${IconArrowRight.id}`}
                        size={ICON_SIZES.MEDIUM}
                      />
                    </InternalLink>
                  </li>
                ))}
              </ul>
            ) : null}
          </li>
        )
      })}
    </ul>
  )
}
